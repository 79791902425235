import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import { AccountBox, Scanner } from "@material-ui/icons";
import { withRouter } from "react-router-dom";

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      <MenuItemLink
        to="/scan"
        primaryText="DHL Label"
        leftIcon={<Scanner />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        target="_blank"
      />
      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name.charAt(0).toUpperCase() + resource.name.slice(1)
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      <MenuItemLink
        to="/profile"
        primaryText="Profil"
        leftIcon={<AccountBox />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

Menu.propTypes = {
  logout: PropTypes.element,
  onMenuClick: PropTypes.func
};

export default withRouter(Menu);

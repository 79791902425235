import React from "react";
import { Filter, TextInput } from "react-admin";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";

const RelaisColisParcelFilter = props => (
  <Filter {...props}>
    <TextInput source="pid" />
    <TextInput source="numRetour" />
    <TextInput source="smartCode" />
    <TextInput source="waybill" />
    <TextInput source="numBooking" />
  </Filter>
);

const RelaisColisParcelList = props => (
  <ListGuesser
    {...props}
    filters={<RelaisColisParcelFilter />}
    bulkActionButtons={false}
    title="Relais Colis"
  >
    <FieldGuesser source={"relId"} />
    <FieldGuesser source={"pid"} />
    <FieldGuesser source={"numRetour"} />
    <FieldGuesser source={"smartCode"} />
    <FieldGuesser source={"waybill"} />
    <FieldGuesser source={"numBooking"} />
    <FieldGuesser source={"errorCodeMessage"} />
    <FieldGuesser source={"dateTime"} />
  </ListGuesser>
);

export { RelaisColisParcelList };

const entrypoint = process.env.API_URL;

async function get(url) {
  const response = await fetch(entrypoint + "/" + url);

  return await response.json();
}

export { get };

export default { get };

import React from "react";
import { FieldGuesser, ListGuesser } from "@api-platform/admin";

const RoleList = props => (
  <ListGuesser {...props} bulkActionButtons={false} title="Rôles">
    <FieldGuesser source="name" />
  </ListGuesser>
);

export { RoleList };

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextField, Button, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

const PASSWORD_REGEX = "^(?=.*[a-z])(?=.*[0-9]).{8,}$";

function Profile(props) {
  const [plainPassword, setPlainPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  function snackbarClose() {
    setSnackbarOpen(false);
  }

  async function save(event) {
    try {
      await props.userService.changePassword(plainPassword);
    } catch (e) {
      console.error(e);
      return;
    }
    setSnackbarMessage("Mot de passe mis à jour");
    setSnackbarOpen(true);
  }

  function changePasswordIfValid(plainPassword) {
    const result = plainPassword.match(new RegExp(PASSWORD_REGEX), "i");
    setPlainPassword(plainPassword);
    setPasswordValid(result !== null);
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Profil" />
        <CardContent>
          <Grid container direction={"column"} wrap={"wrap"}>
            <Grid item>
              <Typography variant="body2" component="p">
                Changer votre mot de passe
                <br />
                <small>
                  8 caractères minimum, contenant au moins une lettre et un
                  chiffre
                </small>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                type="password"
                label="Mot de passe"
                value={plainPassword}
                regex={PASSWORD_REGEX}
                onChange={event => changePasswordIfValid(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={save}
                disabled={!passwordValid}
                style={{ marginTop: 16 }}
              >
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={snackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
}

Profile.propTypes = {
  userService: PropTypes.object,
  iri: PropTypes.string
};

export default Profile;

export { PASSWORD_REGEX };

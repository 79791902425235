import React from "react";
import {
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required
} from "react-admin";
import {
  ListGuesser,
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser
} from "@api-platform/admin";

const IataList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source="code" />
    <ReferenceArrayField
      label="Facilities"
      source="facilities"
      reference="facilities"
    >
      <SingleFieldList>
        <ChipField source="code" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ListGuesser>
);

const IataCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="code" validate={required()} />
    <ReferenceArrayInput
      label="Facilities"
      source="facilities"
      reference="facilities"
    >
      <AutocompleteArrayInput optionText="code" />
    </ReferenceArrayInput>
  </CreateGuesser>
);

const IataEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="code" />
    <ReferenceArrayInput
      label="Facilities"
      source="facilities"
      reference="facilities"
    >
      <AutocompleteArrayInput optionText="code" />
    </ReferenceArrayInput>
  </EditGuesser>
);

export { IataCreate, IataList, IataEdit };

import userService from "./userService";

function authProvider(baseUrl) {
  return {
    login: ({ username, password }) => {
      const request = new Request(baseUrl + "/login_check", {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" })
      });
      return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(({ token }) => {
          userService.persistUser(token);
        });
    },
    logout: () => {
      userService.removeUser();
      return Promise.resolve();
    },
    checkError: error => {
      const status =
        error.response !== undefined ? error.response.status : error.status;
      if (status === 401 || status === 403) {
        userService.removeUser();
        return Promise.reject();
      }

      return Promise.resolve();
    },
    checkAuth: () =>
      userService.getUser() !== null ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {
      const user = userService.getUser();
      return user !== null ? Promise.resolve(user.roles) : Promise.reject();
    }
  };
}

export default authProvider;

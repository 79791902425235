import axios from "axios";
import { getRawToken } from "./app/userService";
import { dataProvider } from "./app/dataProvider";
import { get } from "./app/client";

const entrypoint = process.env.API_URL;

function pieceDetailsSearch(smartCode) {
  const token = getRawToken();
  return axios.get(entrypoint + "/pieces/" + smartCode + "/details", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

async function pieceSetStatus(piece, status) {
  const data = { status: { value: status } };
  await dataProvider.update("pieces", {
    id: piece["@id"],
    data
  });
}

async function allIatas() {
  const data = await get("iatas?pagination=false");
  return data["hydra:member"];
}

export { pieceDetailsSearch, pieceSetStatus, allIatas };

import React from "react";
import { Route } from "react-router-dom";
import Profile from "../page/profile";
import Scan from "../page/scan/scan";
import userService from "./userService";

export default [
  <Route exact path="/scan" component={Scan} noLayout={true} key="scan" />,
  <Route
    exact
    path="/profile"
    noLayout={false}
    key="profile"
    render={props => (
      <Profile
        {...props}
        userService={userService}
        iri={userService.getIri()}
      />
    )}
  />
];

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-final-form";
import {
  SingleFieldList,
  ChipField,
  EmailField,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  PasswordInput,
  BooleanField,
  BooleanInput,
  TextInput,
  Filter,
  email,
  regex
} from "react-admin";
import { ListGuesser, CreateGuesser, EditGuesser } from "@api-platform/admin";
import { allIatas } from "../apiClient";
import { PASSWORD_REGEX } from "../page/profile";
import { Grid, useTheme, Switch } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
const validateEmail = email();
const validatePassword = regex(
  new RegExp("(^$)|(" + PASSWORD_REGEX + ")", "i"),
  "Le mot de passe doit faire au moins 8 caractères et contenir des chiffres et des lettres."
);

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Recherche" source="email" alwaysOn />
  </Filter>
);

const UserList = props => (
  <ListGuesser {...props} filters={<ListFilter />} title="Utilisateurs">
    <EmailField source="email" />
    <ReferenceArrayField
      label="Rôles"
      source="rolesCollection"
      reference="roles"
      sortable={false}
    >
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
    <BooleanField source="active" label="Activé ?" />
  </ListGuesser>
);

function UserCreate(props) {
  const [iatas, setIatas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const iatas = await allIatas();
      setIatas(iatas);
    }
    fetchData();
  }, []);

  return (
    <CreateGuesser {...props}>
      <TextInput source="email" type="email" validate={validateEmail} />
      <PasswordInput
        source="plainPassword"
        validate={validatePassword}
        initialValue={""}
      />
      <ReferenceArrayInput
        label="Roles"
        source="rolesCollection"
        reference="roles"
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <BooleanInput source="active" />
      <Iatas iatas={iatas} />
    </CreateGuesser>
  );
}

function UserEdit(props) {
  const [iatas, setIatas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const iatas = await allIatas();
      setIatas(iatas);
    }
    fetchData();
  }, []);

  return (
    <EditGuesser {...props}>
      <TextInput source="email" type="email" validate={validateEmail} />
      <PasswordInput
        source="plainPassword"
        validate={validatePassword}
        initialValue={""}
      />
      <ReferenceArrayInput
        label="Roles"
        source="rolesCollection"
        reference="roles"
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <BooleanInput source="active" />

      <Iatas iatas={iatas} />
    </EditGuesser>
  );
}

function Iatas(props) {
  const { iatas } = props;
  const form = useForm();

  useEffect(() => {
    let formValues = form.getState().values.facilities ?? {};

    // permet de créer le placeholder pour les iatas dont l'utilisateur n'avait pas encore la connaissance
    for (const iata of iatas) {
      if (formValues[iata.code] === undefined) {
        formValues[iata.code] = {};
      }
    }

    form.change("facilities", JSON.parse(JSON.stringify(formValues)));
  }, [iatas, form]);

  return (
    <Grid container>
      {iatas.map((iata, index) => (
        <Iata key={iata.code} iata={iata} index={index} />
      ))}
    </Grid>
  );
}

Iatas.propTypes = {
  iatas: PropTypes.array
};

function Iata(props) {
  const { iata, index } = props;
  const form = useForm();
  const theme = useTheme();
  const [facilities, setFacilites] = useState({});

  // permet de setter les facilites locales et de mettre à jour les valeur dans le formulaire aussi
  const updateFacilities = useCallback(
    newFacilities => {
      setFacilites(newFacilities);
      const formState = form.getState();
      const formFacilitiesClone = JSON.parse(
        JSON.stringify(formState.values.facilities ?? {})
      );
      formFacilitiesClone[iata.code] = newFacilities;
      form.change("facilities", formFacilitiesClone);
    },
    [form, iata.code]
  );

  // pour chaque facility d'un iata, si on a une valeur dans le form (qui vient de l'user), on la règle
  // on peut avoir des facilities dans le form qui n'existent plus ou alors de facilities que le form n'a pas.
  useEffect(() => {
    const formState = form.getState();
    const formFacilites = formState.values.facilities ?? {};
    const defaultFacilities = {};
    for (const facility of iata.facilities) {
      defaultFacilities[facility.code] =
        formFacilites[iata.code] !== undefined &&
        formFacilites[iata.code][facility.code] !== undefined &&
        formFacilites[iata.code][facility.code] === true;
    }
    updateFacilities(defaultFacilities);
  }, [iata, form, updateFacilities]);

  function applyValueToAll(code, value) {
    const newFacilities = {};
    for (const facility of iata.facilities) {
      newFacilities[facility.code] = value;
    }
    updateFacilities(newFacilities);
  }

  function toggle(code) {
    const newFacilities = JSON.parse(JSON.stringify(facilities));
    newFacilities[code] = !newFacilities[code];
    updateFacilities(newFacilities);
  }

  return (
    <Grid
      item
      style={{
        width: 150,
        background:
          index % 2 === 0 ? theme.palette.grey[300] : theme.palette.grey[100],
        marginRight: 24,
        marginBottom: 24,
        padding: 16
      }}
    >
      <Grid container justify={"space-evenly"} alignItems={"center"}>
        <span>{iata.code}</span>
        <span
          onClick={() => {
            applyValueToAll(iata.code, true);
          }}
        >
          <Check />
        </span>
        <span
          onClick={() => {
            applyValueToAll(iata.code, false);
          }}
        >
          <Close />
        </span>
      </Grid>
      {Object.keys(facilities).map(facilityCode => (
        <Facility
          key={facilityCode}
          facilityCode={facilityCode}
          checked={facilities[facilityCode] === true}
          onClick={() => {
            toggle(facilityCode);
          }}
        />
      ))}
    </Grid>
  );
}

Iata.propTypes = {
  iata: PropTypes.object,
  index: PropTypes.number
};
function Facility(props) {
  const { facilityCode, checked, onClick } = props;

  return (
    <div>
      <code>{facilityCode}</code>
      <Switch checked={checked} onClick={onClick} />
    </div>
  );
}

Facility.propTypes = {
  facilityCode: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func
};

export { UserCreate, UserList, UserEdit };

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  barcodeImage: {
    padding: "0 10%",
    textAlign: "center",
    maxHeight: "100px",
    overflow: "hidden",
    marginBottom: 32,
    cursor: "pointer"
  }
});

const BarcodeImage = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.barcodeImage}>
      <img
        onClick={onClick}
        src={process.env.API_URL + "/barcode/" + value + ".svg"}
        alt={value}
        style={{ width: "100%" }}
      />
    </div>
  );
};
BarcodeImage.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default BarcodeImage;

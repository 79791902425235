import React from "react";
import { AppBar as BaseAppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getUser } from "./userService";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#ffcc00"
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  }
});

const AppBar = props => {
  const classes = useStyles();
  const user = getUser();
  return (
    <BaseAppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src="/dhl25.png" alt="Logo" />
      <span className={classes.spacer} />
      <span>{user.username}</span>
    </BaseAppBar>
  );
};

export default AppBar;

import decodeJwt from "jwt-decode";
import { dataProvider } from "./dataProvider";

function getRawToken() {
  return localStorage.getItem("token");
}

function getIri() {
  const user = getUser();
  return user === null ? null : user.iri;
}

function getUser() {
  const token = localStorage.getItem("token");
  if (null === token) {
    return null;
  }

  const decodedToken = decodeJwt(token);

  return {
    username: decodedToken.username,
    iri: decodedToken.iri,
    roles: decodedToken.roles
  };
}

function persistUser(token) {
  const decodedToken = decodeJwt(token);
  localStorage.setItem("token", token);
  localStorage.setItem("roles", decodedToken.roles);
}

function removeUser() {
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
}

function changePassword(plainPassword) {
  const user = getUser();
  if (null === user) {
    throw Error(
      "L'utilisateur n'est pas loggé, impossible de changer son mot de passe."
    );
  }
  return dataProvider.update("users", {
    id: user.iri,
    data: { plainPassword }
  });
}

export {
  getUser,
  persistUser,
  removeUser,
  changePassword,
  getRawToken,
  getIri
};

export default {
  getUser,
  persistUser,
  removeUser,
  changePassword,
  getRawToken,
  getIri
};

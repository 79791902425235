import React from "react";
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  required
} from "react-admin";
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser
} from "@api-platform/admin";

const FacilityList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source="code" />
    <ReferenceArrayField label="Iatas" source="iatas" reference="iatas">
      <SingleFieldList>
        <ChipField source="code" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ListGuesser>
);

const FacilityEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="code" validate={required()} />
    <ReferenceArrayInput label="Iatas" source="iatas" reference="iatas">
      <AutocompleteArrayInput optionText="code" />
    </ReferenceArrayInput>
  </EditGuesser>
);

const FacilityCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="code" validate={required()} />
    <ReferenceArrayInput label="Iatas" source="iatas" reference="iatas">
      <AutocompleteArrayInput optionText="code" />
    </ReferenceArrayInput>
  </CreateGuesser>
);

export { FacilityList, FacilityCreate, FacilityEdit };

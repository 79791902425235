import React from "react";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Filter, TextInput, BooleanInput } from "react-admin";
import { Switch } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { refreshView } from "ra-core";
import { pieceSetStatus } from "../apiClient";

const PieceFilter = props => (
  <Filter {...props}>
    <TextInput label="WB, Smart, PID" source="q" alwaysOn />
    <BooleanInput label="Voir tous les colis" source="seeAll" alwaysOn />
  </Filter>
);

const PieceDoneButton = ({ label, record }) => {
  const dispatch = useDispatch();
  const done = record.status === "done";
  async function save() {
    await pieceSetStatus(record, done ? "todo" : "done");
    dispatch(refreshView());
  }

  return <Switch checked={done} onClick={save} />;
};
PieceDoneButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object
};

const PieceDoneData = ({ label, record }) => {
  return (
    <React.Fragment>
      {record["shipmentSummary"]["nbPieces"] +
        "/" +
        record["shipmentSummary"]["pieceDoneCount"]}
    </React.Fragment>
  );
};
PieceDoneData.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object
};

const ShipmentSummaryField = ({ label, record, field }) => {
  return <React.Fragment>{record["shipmentSummary"][field]}</React.Fragment>;
};
ShipmentSummaryField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  field: PropTypes.string
};

const PieceList = props => (
  <ListGuesser
    {...props}
    filters={<PieceFilter />}
    bulkActionButtons={false}
    title="DHL Colis"
  >
    <ShipmentSummaryField label={"Waybill"} field={"waybill"} />
    <ShipmentSummaryField label={"Area"} field={"iata"} />
    <ShipmentSummaryField label={"Facility"} field={"facility"} />
    <FieldGuesser source={"smartCode"} label={"SMART"} />
    <FieldGuesser source={"pid"} label={"PID"} />
    <FieldGuesser source={"dropOffDateTime"} label={"Date Dépôt"} />
    <FieldGuesser source={"numRetour"} />
    <PieceDoneButton label={"Traité"} />
    <PieceDoneData label={"#Piece/Label"} />
  </ListGuesser>
);

export { PieceList };

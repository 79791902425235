import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import BarcodeImage from "../../components/BarcodeImage";

const styles = theme => ({
  large: {
    fontSize: "255%"
  },
  largeTypography: {
    fontSize: "50%"
  },
  copyToClipboard: {
    cursor: "pointer"
  },
  pieceContainer: {
    textAlign: "center"
  }
});

class Piece extends React.Component {
  keydownEventListener = event => {
    if (event.code !== "Enter") {
      return;
    }
    event.preventDefault();
    document.removeEventListener("keydown", this.keydownEventListener);

    this.props.handleDataClick(null);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keydownEventListener);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownEventListener);
  }

  render() {
    const {
      classes,
      piece,
      nbPiece,
      nbPieceDone,
      waybill,
      iata,
      facility,
      handleDataClick
    } = this.props;
    const done = piece.status === "done";

    return (
      <Grid
        container
        direction={"column"}
        wrap={"wrap"}
        className={classes.pieceContainer}
      >
        <Grid item className={classes.large}>
          <Typography
            className={classes.largeTypography}
            variant="subtitle1"
            gutterBottom
          >
            Service Area: <strong>{iata}</strong> / Facility:{" "}
            <strong>{facility}</strong> / WB:{" "}
            <strong
              className={classes.copyToClipboard}
              onClick={() => handleDataClick(waybill)}
            >
              {waybill}
            </strong>
            {" / Colis traités: "} {nbPieceDone}/<strong>{nbPiece}</strong>
          </Typography>
          {done && (
            <Card style={{ width: "80%", margin: "0 10%" }}>
              <CardContent>
                Ce colis est{" "}
                <span style={{ color: "#22b422" }}>déjà traité</span>.
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item style={{ paddingTop: 24 }}>
          <Typography variant="h3" gutterBottom>
            PID:{" "}
            <code
              className={classes.copyToClipboard}
              onClick={() => handleDataClick(piece.pid)}
            >
              {piece.pid}
            </code>
          </Typography>
          <BarcodeImage
            value={piece.pid}
            onClick={() => handleDataClick(piece.pid)}
          />
        </Grid>
      </Grid>
    );
  }
}

Piece.propTypes = {
  classes: PropTypes.object,
  piece: PropTypes.object,
  nbPiece: PropTypes.number,
  nbPieceDone: PropTypes.number,
  waybill: PropTypes.string,
  iata: PropTypes.string,
  facility: PropTypes.string,
  handleDataClick: PropTypes.func
};

export default withStyles(styles)(Piece);
